import { z } from "zod";
import { NotificationBannerSchema } from "@abridge/soap-common";

/**
 * Enum of known LaunchDarkly flag keys.
 */
export enum KnownFlagKey {
  CodingHccIcd10 = "soap-dashboard-coding-hcc-icd10",
  CodingLevelOfService = "coding-level-of-service",
  EncounterDataUseClientAPI = "encounter-data-use-client-api",
  NoteEditorGenerateTestAppointments = "note-editor-generate-test-appointments",
  NoteGenerationSandboxMode = "note-generation-sandbox-mode",
  NoteGenerationAlternativeNotes = "note-generation-alternative-notes",
  NoteProxyAudioUrl = "note-editor-proxy-audio-url",
  NoteSettingsPhysicalExamTemplates = "note-settings-physical-exam-templates",
  NoteSettingsPVSConcise = "note-settings-pvs-concise",
  NoteSettingsCustomPhysicalExamTemplates = "note-settings-custom-physical-exam-templates",
  NoteSettingsV2CustomPhysicalExamTemplates = "note-settings-v2-custom-physical-exam-templates",
  NoteSettingsTimeSpentInEncounterSubsection = "note-settings-time-spent-in-encounter-subsection",
  QaViewerAdvancedSearch = "soap-dashboard-qa-viewer-advanced-search",
  QaViewerHccIcd10Ui = "soap-dashboard-qa-viewer-enable-cdi-ui",
  QaViewerLosUi = "soap-dashboard-qa-viewer-enable-los-ui",
  QaViewerOrders = "soap-dashboard-qa-viewer-enable-orders",
  QaViewerLinkedEvidence = "soap-dashboard-qa-viewer-linked-evidence",
  RemoteConfiguration = "remote-configuration",
  SendPvs = "send-pvs",
  ShowIntegratedAndInsideSendToEhrConflictWarning = "show-integrated-and-inside-send-to-ehr-conflict-warning",
  UniversalNotificationBanner = "client-universal-notification-banner",
  UseNewNoteViewerTabs = "use-new-note-viewer-tabs",
  VirtualVisits = "virtual-visits",
  WebRecording = "web-recording",
  WebRecordingFallbackKeyPairGeneration = "fallback-key-pair-generation",
  WebRecordingMinimumRecordingStorageMib = "web-recording-minimum-recording-storage-mib",
  WebRecordingOnboarding = "web-recording-onboarding",
  WebRecordingDebugger = "web-recording-debugger-ui",
  WebRecordingDeleteOnEncounterAlreadyProcessed = "web-recording-delete-on-encounter-already-processed",
  WebRecordingLockRecordingToInitialBrowser = "web-recording-lock-recording-to-initial-browser",
}

export const KnownFlagValueRecord = z
  .object({
    [KnownFlagKey.CodingHccIcd10]: z.boolean(),
    [KnownFlagKey.CodingLevelOfService]: z.boolean(),
    [KnownFlagKey.EncounterDataUseClientAPI]: z.boolean(),
    [KnownFlagKey.NoteEditorGenerateTestAppointments]: z.boolean(),
    [KnownFlagKey.NoteGenerationSandboxMode]: z.boolean(),
    [KnownFlagKey.NoteGenerationAlternativeNotes]: z.boolean(),
    [KnownFlagKey.NoteSettingsPhysicalExamTemplates]: z.boolean(),
    [KnownFlagKey.NoteSettingsPVSConcise]: z.boolean(),
    [KnownFlagKey.NoteSettingsTimeSpentInEncounterSubsection]: z.boolean(),
    [KnownFlagKey.NoteSettingsCustomPhysicalExamTemplates]: z.boolean(),
    [KnownFlagKey.NoteSettingsV2CustomPhysicalExamTemplates]: z.boolean(),
    [KnownFlagKey.NoteProxyAudioUrl]: z.boolean(),
    [KnownFlagKey.QaViewerAdvancedSearch]: z.boolean(),
    [KnownFlagKey.QaViewerHccIcd10Ui]: z.boolean(),
    [KnownFlagKey.QaViewerLosUi]: z.boolean(),
    [KnownFlagKey.QaViewerOrders]: z.boolean(),
    [KnownFlagKey.QaViewerLinkedEvidence]: z.boolean(),
    [KnownFlagKey.RemoteConfiguration]: z.boolean(),
    [KnownFlagKey.SendPvs]: z.boolean(),
    [KnownFlagKey.ShowIntegratedAndInsideSendToEhrConflictWarning]: z.boolean(),
    [KnownFlagKey.UniversalNotificationBanner]: NotificationBannerSchema,
    [KnownFlagKey.UseNewNoteViewerTabs]: z.boolean(),
    [KnownFlagKey.VirtualVisits]: z.boolean(),
    [KnownFlagKey.WebRecording]: z.boolean(),
    [KnownFlagKey.WebRecordingFallbackKeyPairGeneration]: z.boolean(),
    [KnownFlagKey.WebRecordingMinimumRecordingStorageMib]: z
      .number()
      .int()
      .positive(),
    [KnownFlagKey.WebRecordingOnboarding]: z.boolean(),
    [KnownFlagKey.WebRecordingDebugger]: z.boolean(),
    [KnownFlagKey.WebRecordingDeleteOnEncounterAlreadyProcessed]: z.boolean(),
    [KnownFlagKey.WebRecordingLockRecordingToInitialBrowser]: z.boolean(),
  })
  .partial();

export type KnownFlagValueRecord = z.infer<typeof KnownFlagValueRecord>;

/**
 * Returns the value type for a given flag key.
 */
export type KnownFlagValue<K> = K extends KnownFlagKey
  ? NonNullable<KnownFlagValueRecord[K]>
  : never;

/**
 * Returns the schema for parsing a known flag value.
 */
export const getKnownFlagSchema = <K extends KnownFlagKey>(key: K) =>
  KnownFlagValueRecord.shape[key];
