import { useEffect } from "react";
import { canUseDOM } from "@utils/common";
import { sentryError } from "@integrations/sentry";
import { useEnvironmentVariables } from "@contexts/environment";

type ZendeskAction = "show" | "hide" | "open" | "logout";
export interface IZendeskWindow extends Window {
  zE: (widget: string, action: ZendeskAction) => void;
}
const SCRIPT_ID = "ze-snippet";

export const getZendeskClient = (): IZendeskWindow["zE"] | null => {
  if (!canUseDOM()) {
    throw new Error(
      "Zendesk client access failed: DOM is not available. Ensure this code is running in a browser environment.",
    );
  }
  return (window as unknown as IZendeskWindow).zE || null;
};
const ZENDESK_RETRY_CONFIG = {
  maxRetries: 15,
  retryInterval: 1000,
};
const executeWithZendeskClient = (
  action: (zE: IZendeskWindow["zE"]) => void,
  isUserInitiated = false, // Whether this action was directly initiated by user interaction
) => {
  let attempts = 0;
  const attemptAction = () => {
    try {
      const zE = getZendeskClient();
      if (zE) {
        action(zE);
      } else if (attempts < ZENDESK_RETRY_CONFIG.maxRetries) {
        attempts++;
        setTimeout(attemptAction, ZENDESK_RETRY_CONFIG.retryInterval);
      } else {
        const error = new Error(
          `Zendesk client not available after ${ZENDESK_RETRY_CONFIG.maxRetries} attempts`,
        );
        // Only report to Sentry if this was a user-initiated action
        if (isUserInitiated) {
          sentryError(error, {
            extra: {
              error,
            },
            tags: {
              uiRegion: "ZendeskChatWidget",
            },
          });
        }
        // Otherwise, silently fail without reporting
      }
    } catch (err) {
      const error = new Error("Zendesk client error");
      // Only report to Sentry if this was a user-initiated action
      if (isUserInitiated) {
        sentryError(error, {
          extra: {
            error: err,
          },
          tags: {
            uiRegion: "ZendeskChatWidget",
          },
        });
      }
      // Otherwise, silently fail without reporting
    }
  };
  attemptAction();
};

export const openZendeskChat = (): void => {
  executeWithZendeskClient((zE) => {
    zE("webWidget", "show");
    zE("webWidget", "open");
  }, true); // This is user-initiated, so report errors
};

export const hideZendeskChat = (): void => {
  executeWithZendeskClient((zE) => {
    zE("webWidget", "hide");
  });
};

export const logoutZendeskChat = (): void => {
  executeWithZendeskClient((zE) => {
    zE("webWidget", "hide");
    zE("webWidget", "logout");
  });
};

const ZendeskLauncher = () => {
  const envVariables = useEnvironmentVariables();

  useEffect(() => {
    if (!envVariables.NEXT_PUBLIC_ZENDESK_CHAT_KEY) {
      sentryError("Zendesk Chat is unavailable. Please configure the API key.");
      return;
    }
    // Check if the script is already loaded
    const existingScript = document.getElementById(SCRIPT_ID);
    if (existingScript) {
      return;
    }

    const script = document.createElement("script");
    script.id = SCRIPT_ID;
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${envVariables.NEXT_PUBLIC_ZENDESK_CHAT_KEY}`;
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      hideZendeskChat();
    };
    script.onerror = (error) => {
      sentryError(new Error("Zendesk Chat widget script failed to load."), {
        extra: {
          error,
        },
        tags: {
          uiRegion: "ZendeskChatWidget",
        },
      });
    };

    return () => {
      // Clean up the script once the component unmounts
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  // This component does not render any UI elements. It is responsible for
  // loading the Zendesk chat widget script and managing its lifecycle.
  return null;
};

export default ZendeskLauncher;
