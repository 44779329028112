import {
  FirestoreCollection,
  Firestore_Soap_Conversation,
  Firestore_Soap_Encounter,
  Firestore_User_Account,
  UserProfile,
} from "@abridge/soap-common";
import { sentryError } from "@integrations/sentry";
import { SimpleObject } from "@types_";
import {
  DocumentSnapshot,
  FirestoreError,
  QuerySnapshot,
  Unsubscribe,
  collection,
  doc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import { postToRoute } from "./axios";
import { ErrorResponse } from "@dto";
import { AxiosResponse } from "axios";

const updateDocumentAttributes = async <T = SimpleObject>(
  collectionName: string,
  docId: string,
  data: T,
): Promise<void> => {
  const firestore = getFirestore();
  const docRef = doc(firestore, collectionName, docId);
  return updateDoc(docRef, {
    ...(data || {}),
  });
};

export const updateConversationAttributes = (
  encounterId: string,
  data: Firestore_Soap_Conversation,
): Promise<void> =>
  updateDocumentAttributes<Firestore_Soap_Conversation>(
    FirestoreCollection.CONVERSATIONS,
    encounterId,
    data,
  );

export const updateEncounterAttributes = async (
  encounterId: string,
  data: Firestore_Soap_Encounter,
): Promise<void> =>
  updateDocumentAttributes<Firestore_Soap_Encounter>(
    FirestoreCollection.ENCOUNTERS,
    encounterId,
    data,
  );

export const updateUserAccountAttributes = async (
  userId: string,
  data: Firestore_User_Account,
): Promise<void> => {
  try {
    updateDocumentAttributes<Firestore_User_Account>(
      FirestoreCollection.USER_ACCOUNTS,
      userId,
      data,
    );
  } catch (err) {
    sentryError(err);
  }
};

export const subscribeUserAccountListener = async (
  userId: string,
  onSnapshotCallback: (
    snapshot: DocumentSnapshot<Firestore_User_Account>,
  ) => void,
  onErrorCallback: (error: FirestoreError) => void,
): Promise<Unsubscribe | undefined> => {
  const firestore = getFirestore();
  try {
    const userDocRef = doc(
      firestore,
      FirestoreCollection.USER_ACCOUNTS,
      userId,
    );
    const unsubscribeListener = onSnapshot<Firestore_User_Account>(
      userDocRef,
      {},
      onSnapshotCallback,
      onErrorCallback,
    );
    return unsubscribeListener;
  } catch (err) {
    console.error("Failed to log in to firebase client SDK", err);
    sentryError(err);
    return;
  }
};

/**
 * Listen to the entire list of encounters for the current date (for non-integrated users)
 * Imp: Make sure query is in-line with the /notes/fetch call
 */
export const subscribeEncounterListListener = async (
  userId: string,
  currentSelectedDate: Date,
  onSnapshotCallback: (
    snapshot: QuerySnapshot<Firestore_Soap_Encounter>,
  ) => void,
  onErrorCallback: (error: FirestoreError) => void,
): Promise<Unsubscribe | undefined> => {
  const firestore = getFirestore();
  try {
    if (!userId || !currentSelectedDate) return;

    const queryStartDate = DateTime.fromJSDate(currentSelectedDate)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .toJSDate();
    const queryEndDate = DateTime.fromJSDate(currentSelectedDate)
      .set({
        hour: 23,
        minute: 59,
        second: 59,
      })
      .toJSDate();
    const limitSize = 1000; // Reasonable upper limit per day.
    // IMP! This query should be in sync with the query used to fetch encounters in the /notes/fetch api endpoint.
    const encQuery = query(
      collection(firestore, FirestoreCollection.ENCOUNTERS),
      where("source", "==", "CLINICIAN_RECORDER"),
      where("subject", "==", userId),
      where("callInitiated", ">=", queryStartDate),
      where("callInitiated", "<=", queryEndDate),
      orderBy("callInitiated", "asc"),
      limit(limitSize),
    );
    const unsubscribeListener = onSnapshot<Firestore_Soap_Encounter>(
      encQuery,
      {},
      onSnapshotCallback,
      onErrorCallback,
    );
    return unsubscribeListener;
  } catch (err) {
    console.error("Failed to subscribe to to review page 1 listener", err);
    sentryError(err);
    return;
  }
};

/**
 * Subscribe to each individual recorded encounter in the current shown scheduled appointment list
 * (Integrated user list)
 */
export const subscribeToScheduledEncounters = async (
  encounterIds: string[],
  onSnapshotCallback: (
    snapshot: DocumentSnapshot<Firestore_Soap_Encounter>,
  ) => void,
  onErrorCallback: (error: FirestoreError) => void,
): Promise<Unsubscribe[] | undefined> => {
  const firestore = getFirestore();
  try {
    const unsubListeners = [];
    for await (const encId of encounterIds) {
      if (!encId) continue;
      unsubListeners?.push(
        onSnapshot<Firestore_Soap_Encounter>(
          doc(firestore, FirestoreCollection.ENCOUNTERS, encId),
          {},
          onSnapshotCallback,
          onErrorCallback,
        ),
      );
    }
    return unsubListeners;
  } catch (error) {
    sentryError(error);
  }
  return;
};

/**
 * Updates user profile.
 *
 * Debounced so that it can only be called once every second.
 **/
export const updateUserProfile = (
  userProfile: UserProfile,
): Promise<AxiosResponse<void | ErrorResponse>> =>
  postToRoute("/api/user/updateProfile", {
    ...userProfile,
  });
