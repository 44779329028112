import AbridgeToastContainer from "@components/AbridgeToast";
import ErrorBoundary from "@components/ErrorBoundary";
import { metaKeywords } from "@constants";
import { UserContextProvider } from "@contexts/UserContext";
import { initAmplitude } from "@integrations/amplitude";
import { initBraze } from "@integrations/braze";
import { disableDevToolsInProduction, isDetectifyBot } from "@utils/common";
import initAuth, { initClientAuth } from "@utils/initAuth";
import { triggerTabCheck } from "@utils/multitab";
import { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import { ReactElement, ReactNode, useEffect } from "react";
import { once } from "lodash";

// Global style imports
import "@components/OTPInput/OTPInput.scss";
import "@styles/abridge-toast.scss";
import "@styles/datepicker-overrides.scss";
import "react-toastify/dist/ReactToastify.css";
import "@styles/global.scss";
import "@styles/theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";

import { LaunchDarklyProvider } from "@components/LaunchDarklyProvider";
import { ThemeContextProvider } from "@contexts/ThemeContext";
import { EnvironmentVariablesProvider } from "@contexts/environment";
import { initSentry } from "@integrations/sentry";
import { EnvironmentVariablesProps } from "@contexts/environment/types";

initAuth();

/**
 * This code causes problems when ran more than once.
 * https://www.notion.so/abridge/2025-02-06-Web-Recorder-Worklist-not-loading-for-Integrated-Apps-192c967bdf098036b0eecf9fc12b9f70?pvs=4
 */
const initializeServices = once(
  (environmentVariables: EnvironmentVariablesProps["environmentVariables"]) => {
    const {
      NEXT_PUBLIC_AMPLITUDE_API_ENDPOINT,
      NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY,
      NEXT_PUBLIC_BRAZE_CLIENT_KEY,
      NEXT_PUBLIC_DEPLOY_ENV,
      NEXT_PUBLIC_SENTRY_DSN,
      NEXT_PUBLIC_SENTRY_RELEASE,
    } = environmentVariables;

    initSentry({
      dsn: NEXT_PUBLIC_SENTRY_DSN,
      environment: NEXT_PUBLIC_DEPLOY_ENV ?? "local",
      release: NEXT_PUBLIC_SENTRY_RELEASE,
    });

    if (!isDetectifyBot()) {
      initAmplitude({
        apiEndpoint: NEXT_PUBLIC_AMPLITUDE_API_ENDPOINT,
        clientKey: NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY,
      });
      initBraze({
        clientKey: NEXT_PUBLIC_BRAZE_CLIENT_KEY,
      });
    }
    triggerTabCheck();
    disableDevToolsInProduction(NEXT_PUBLIC_DEPLOY_ENV);
  },
);

type NextPageWithLayout = NextPage<EnvironmentVariablesProps> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<EnvironmentVariablesProps> & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout): JSX.Element {
  initClientAuth({
    apiKey: pageProps.environmentVariables.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
    authDomain: pageProps.environmentVariables.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL:
      pageProps.environmentVariables.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: pageProps.environmentVariables.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    appId: pageProps.environmentVariables.NEXT_PUBLIC_FIREBASE_APP_ID,
  });

  useEffect(() => {
    // Call the once-wrapped initialization function
    initializeServices(pageProps.environmentVariables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <title>{`${
          pageProps.environmentVariables.NEXT_PUBLIC_DEPLOY_ENV !== "production"
            ? `${(
                pageProps.environmentVariables.NEXT_PUBLIC_DEPLOY_ENV || ""
              )?.toUpperCase?.()} | `
            : ""
        }Abridge Clinician | Editor`}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content={metaKeywords} />
        <meta
          name="description"
          content="Go from dialogue to structured clinical document, simply by recording your patient visits. Completing your SOAP note has never been easier."
        />
        <link rel="shortcut icon" href="icons/favicon.ico" />
      </Head>
      <EnvironmentVariablesProvider
        environmentVariables={pageProps.environmentVariables}
      >
        <ThemeContextProvider>
          <ErrorBoundary>
            <LaunchDarklyProvider>
              <UserContextProvider>
                {getLayout(<Component {...pageProps} />)}
              </UserContextProvider>
            </LaunchDarklyProvider>
            <AbridgeToastContainer />
          </ErrorBoundary>
        </ThemeContextProvider>
      </EnvironmentVariablesProvider>
    </>
  );
}

export default MyApp;
